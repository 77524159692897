@import "variables";

body {
    background-color: $background-color;
    color: $white;
}

.container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.btn {
    font-size: 18px;
    line-height: calc(17/18);
    border-radius: 24px;
    padding: 14px 20px;
    border: 2px solid transparent;
    color: $white;

    &:hover {
        box-shadow: 0 0 0 0.2rem rgba(0, 214, 111, 0.5);
        border-color: transparent;
    }

    &-primary {
        background: linear-gradient(135deg, $turquoise, $green);
        border-color: transparent;
    }

    &-secondary {
        background-image: linear-gradient($background-color, $background-color),
        linear-gradient(135deg, $turquoise, $green);
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }
}

.input-wrapper {
    position: relative;
    height: 48px;
    border-radius: 10px;

    input {
        background: transparent;
        outline: none;
        border: 2px solid transparent;
        border-radius: 10px;
        font-size: 16px;
        height: 100%;
        width: 100%;
        color: $white;
        padding: 10px 16px;
        background-image: linear-gradient($background-color, $background-color),
        linear-gradient(to left, $white, $white);
        background-origin: border-box;
        background-clip: padding-box, border-box;

        &:focus,
        &:not(:placeholder-shown) {
            padding: 16px 16px 7px;

            & ~ label {
                transform: scale(.625);
                margin-top: 2px;
            }
        }

        &:focus {
            background-image: linear-gradient($background-color, $background-color), linear-gradient(135deg, $turquoise, $green);
        }
    }

    label {
        $margin: 6.5px;
        position: absolute;
        color: $white;
        pointer-events: none;
        top: 0;
        left: 16px;
        margin-top: $margin;
        height: calc(100% - #{$margin} * 2);
        display: flex;
        align-items: center;
        transform-origin: top left;
        transition: all 600ms ease;
    }
}
