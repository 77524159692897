.inputAnswer{
    width: 25%;

    .headline{
        font-size: 50px;
    }

    .content{
        display: flex;
        flex-direction: column;

        .question{
            padding-bottom: 10px;
        }

        .submit-button{
            button{
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}
