.topic-front-page:hover {
  cursor: pointer;
}

.topic-front-page {
  margin-top: 30px;
  border: 4px solid;
  border-radius: 8px;
  padding: 0!important;
  justify-content: space-between;

  &__text {
    padding: 15px;
  }

  &__image {
    width: 40%;
  }
}

.topic {
  margin-top: 30px;

  &__image-container {
    text-align: center;
    height: 70%;
  }

  &__headline {
    margin-top: 15px;
  }
}

.show-work {
  margin: 50px 0 50px 0;
}
